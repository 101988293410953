<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="地图定位"
      top="5vh"
      :visible.sync="showMapDialog"
      width="900px"
    >
      <baidu-map
        class="map"
        :center="center"
        :zoom="zoom"
        :scroll-wheel-zoom="true"
        @ready="handler"
        @click="clickEvent"
      >
        <!--        ak="FzAhPfin2UmY2ZwHiqmGAl2XUlfSxUkY"-->
        <!-- 地图控件位置 -->
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <!-- 城市列表 -->
        <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
        <!-- 定位当前位置 -->
        <bm-geolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :show-address-bar="true"
          :auto-location="true"
          @locationSuccess="getLoctionSuccess"
        ></bm-geolocation>
        <!-- 地图容器 -->
        <bm-view
          :style="{
            width: '100%',
            height: clientHeight + 'px',
            flex: 1,
            marginBottom: '-30px',
          }"
        ></bm-view>
      </baidu-map>
      <div class="demo-input-suffix">
        <el-link type="info">lng：</el-link>
        <el-input
          v-model.number="locData.longitude"
          class="lineinput"
          style="width: 200px"
          size="mini"
        ></el-input>
        <el-link type="info">lat：</el-link>
        <el-input
          v-model.number="locData.latitude"
          class="lineinput"
          style="width: 200px"
          size="mini"
        ></el-input>
        <el-link type="info">address：</el-link>
        <el-input
          v-model="locData.address"
          class="lineinput"
          style="width: 200px"
          size="mini"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="warning"
          size="small"
          icon="el-icon-close"
          @click.native="showMapDialog = false"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-check"
          @click.native="findlocation"
        >
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {
    BaiduMap,
    BmNavigation,
    BmView,
    BmGeolocation,
    BmCityList,
  } from 'vue-baidu-map'
  import avatar from '@/assets/map_marker_check.png'
  export default {
    name: 'MineMap',
    components: { BaiduMap, BmNavigation, BmView, BmGeolocation, BmCityList },

    data() {
      return {
        showMapDialog: false,
        center: { lng: 121.833138, lat: 39.081725 },
        zoom: 12,
        locData: {
          longitude: '',
          latitude: '',
          address: '',
          loc_sheng: '',
          loc_shi: '',
          loc_qu: '',
        },
        clientHeight:
          document.documentElement.clientHeight / 2 < 100
            ? '200'
            : document.documentElement.clientHeight / 2, // 屏幕高度
        avatar: avatar,
      }
    },
    computed: {},
    watch: {
      showMapDialog(val) {
        if (!val) {
          console.log(this.myMarker)
          // this.myMarker = null
          window.map.removeOverlay(this.myMarker)
          this.locData = {
            longitude: '',
            latitude: '',
            address: '',
            loc_sheng: '',
            loc_shi: '',
            loc_qu: '',
          }
        }
      },
    },
    methods: {
      handlerShowMapDialog() {
        this.showMapDialog = true
      },
      handler({ BMap, map }) {
        let _this = this
        // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
        var geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            console.log(r)
            _this.center = { lng: r.longitude, lat: r.latitude } // 设置center属性值
            _this.autoLocationPoint = { lng: r.longitude, lat: r.latitude } // 自定义覆盖物
            _this.initLocation = true
          },
          { enableHighAccuracy: true }
        )

        window.map = map
      },
      //点击地图监听
      clickEvent(e) {
        var that = this
        map.clearOverlays()
        let Icon_0 = new BMap.Icon(that.avatar, new BMap.Size(64, 64), {
          anchor: new BMap.Size(18, 32),
          imageSize: new BMap.Size(36, 36),
        })
        this.myMarker = new BMap.Marker(
          new BMap.Point(e.point.lng, e.point.lat),
          { icon: Icon_0 }
        )
        map.addOverlay(this.myMarker)
        //用所定位的经纬度查找所在地省市街道等信息
        var point = new BMap.Point(e.point.lng, e.point.lat)
        var gc = new BMap.Geocoder()
        let _this = this
        gc.getLocation(point, function (rs) {
          console.log(rs)
          console.log(rs.addressComponents.city)
          var addComp = rs.addressComponents
          // console.log(rs.address) //地址信息
          _this.locData.address = rs.address
          _this.locData.loc_sheng = rs.addressComponents.province
          _this.locData.loc_shi = rs.addressComponents.city
          _this.locData.loc_qu = rs.addressComponents.district
        })

        this.locData.longitude = e.point.lng
        this.locData.latitude = e.point.lat
      },
      //定位成功回调
      getLoctionSuccess(point, AddressComponent, marker) {
        var that = this
        map.clearOverlays()
        let Icon_0 = new BMap.Icon(that.avatar, new BMap.Size(64, 64), {
          anchor: new BMap.Size(18, 32),
          imageSize: new BMap.Size(36, 36),
        })
        this.myMarker = new BMap.Marker(
          new BMap.Point(point.point.lng, point.point.lat),
          { icon: Icon_0 }
        )
        map.addOverlay(this.myMarker)
        this.locData.longitude = point.point.lng
        this.locData.latitude = point.point.lat
      },
      findlocation() {
        console.log(this.locData.address)
        this.$emit('location-data', this.locData)
        this.showMapDialog = false
        // var that = this
        // that.form.Detailedarea = that.locData.address
        // that.form.Latitude =
        //   that.locData.longitude + ' ' + that.locData.latitude
        // that.form.sheng = that.locData.loc_sheng
        // that.form.shi = that.locData.loc_shi
        // that.form.qu = that.locData.loc_qu
        // that.dialogVisiblemap = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .demo-input-suffix {
    position: relative;
    top: 40px;
    background-color: #fff;
  }
</style>
