<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :title="title"
      top="15vh"
      :visible.sync="showDialog"
      width="780px"
      @close="close"
    >
      <div v-loading="loading" class="content">
        <el-form
          ref="form"
          :model="form"
          inline
          :rules="rules"
          label-width="80px"
          style="margin-left: 140px"
        >
          <el-form-item prop="depot_name" label="名称:" style="width: 440px">
            <el-input v-model="form.depot_name" style="width: 240px"></el-input>
          </el-form-item>
          <el-form-item prop="depot_type" label="类型:" style="width: 440px">
            <el-radio v-model="form.depot_type" :label="1" disabled>
              仓库
            </el-radio>
            <el-radio v-model="form.depot_type" :label="2" disabled>
              车辆
            </el-radio>
          </el-form-item>

          <!-- 地址 -->
          <el-form-item
            v-if="form.depot_type == 1"
            prop="depot_address"
            label="地址:"
          >
            <el-input
              v-model="form.depot_address"
              style="width: 240px"
            ></el-input>
            <span
              style="margin-left: 10px; cursor: pointer"
              @click="handlerShowMapDialog"
            >
              <i class="el-icon-location" style="color: #409eff"></i>
              地图定位
            </span>
          </el-form-item>
          <el-form-item
            v-if="form.depot_type == 2"
            prop="depot_carno"
            label="车牌号:"
          >
            <el-input
              v-model="form.depot_carno"
              style="width: 240px"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.depot_type == 2"
            prop="driver_id"
            required
            label="使用人:"
          >
            <!-- <el-input v-model="form.people" style="width: 240px"></el-input> -->
            <el-select
              v-model="form.driver_id"
              filterable
              placeholder="选择销售或送货人"
              style="width: 240px"
            >
              <el-option
                v-for="item in driver_list"
                :key="item.id"
                :label="item.user_name"
                :value="item.id"
              >
                <span style="float: left">{{ item.user_name }}</span>
                <span style="float: right; font-size: 13px; color: #8492a6">
                  {{ item.mobile }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 设置 -->
          <el-form-item :label="label" prop="">
            <el-checkbox
              v-model="form.is_minus"
              :true-label="1"
              :false-label="0"
            >
              {{ tipa }}
            </el-checkbox>
          </el-form-item>
          <el-form-item v-if="form.depot_type == 1" label="下单限制" prop="">
            <el-checkbox
              v-model="form.is_limit_order"
              :true-label="1"
              :false-label="0"
            >
              按可用库存数量限制访销、网销下单数量
            </el-checkbox>
          </el-form-item>
          <el-form-item label="调拨设置:" prop="">
            <el-checkbox
              v-if="form.depot_type == 1"
              v-model="form.is_limit_allot"
              :true-label="1"
              :false-label="0"
            >
              {{ tipb }}
            </el-checkbox>
            <el-checkbox
              v-if="form.depot_type == 2"
              v-model="form.is_allot"
              :true-label="1"
              :false-label="0"
            >
              车存货值大于
              <el-input
                v-model="form.allot_money"
                style="width: 70px"
              ></el-input>
              元，不允许调拨
            </el-checkbox>
          </el-form-item>
          <!-- <el-form-item prop="set" :label="label">
            <div class="check-wrapper">
              <el-checkbox
                v-model="form.is_minus"
                :true-label="1"
                :false-label="0"
              >
                {{ tipa }}
              </el-checkbox>
              <div class="a"></div>
              <div
                :style="{
                  marginLeft: form.depot_type == 1 ? -72 + 'px' : 18 + 'px',
                }"
              >
                <span style="margin-right: 12px">调拨限制:</span>
                <el-checkbox
                  v-if="form.depot_type == 1"
                  v-model="form.is_limit_order"
                  :true-label="1"
                  :false-label="0"
                >
                  {{ tipb }}
                </el-checkbox>
              </div>
              <div class="a"></div>

              <el-checkbox
                v-if="form.depot_type == 2"
                v-model="form.is_allot"
                :true-label="1"
                :false-label="0"
              >
                车存货值大于
                <el-input
                  v-model="form.allot_money"
                  style="width: 70px"
                ></el-input>
                元，不允许调拨
              </el-checkbox>
            </div>
          </el-form-item> -->

          <el-form-item prop="is_close" label="状态:" style="width: 440px">
            <el-radio v-model="form.is_close" :label="0">正常</el-radio>
            <el-radio v-model="form.is_close" :label="1">停用</el-radio>
          </el-form-item>
        </el-form>
        <!-- 底部按钮 -->
        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="close">关闭</el-button>
        </div>
      </div>
      <mine-map ref="baiduMap" @location-data="locationData"></mine-map>
    </el-dialog>
  </div>
</template>
<script>
  import MineMap from './map'
  import { getDetail, editRow, getList } from '@/api/depotManagement'
  export default {
    name: 'Edit',
    components: {
      MineMap,
    },
    data() {
      return {
        // title: '修改仓库',
        showDialog: false,
        loading: true,
        id: 0,
        form: {
          depot_name: '',
          depot_type: '1',
          depot_address: '',
          depot_carno: '',
          is_close: 1,
          is_allot: 0,
          is_minus: 0,
          is_limit_order: 0,
          is_minus: 0,
          allot_money: '',
          driver_id: '',
        },

        driver_list: [],
        // 表单验证规则
        rules: {
          depot_name: [
            {
              required: true,
              message: '请输入仓库名称',
              trigger: 'blur',
            },
            {
              min: 1,
              max: 25,
              message: '长度在 1 到 25 个字符',
              trigger: 'blur',
            },
          ],
          depot_type: [
            {
              required: true,
              message: '请输入仓库类型',
              trigger: 'blur',
            },
          ],
        },
      }
    },
    computed: {
      title() {
        if (this.id != 0) {
          return '修改仓库'
        } else {
          return '添加仓库'
        }
      },
      label() {
        if (this.form.depot_type == 1) {
          return '库存设置:'
        } else if (this.form.depot_type == 2) {
          return '车存设置:'
        } else {
          return ''
        }
      },
      tipa() {
        if (this.form.depot_type == 1) {
          return '允许仓库负库存（库存不足时仍可派单、调拨）'
        } else if (this.form.depot_type == 2) {
          return '允许负车存（车存不足时可先做销售、派送，再补车存）'
        } else {
          return ''
        }
      },
      tipb() {
        if (this.form.depot_type == 1) {
          return '按可用库存限制调拨申请数量'
        } else if (this.form.depot_type == 2) {
          return '按可用库存数量限制下单数量'
        } else {
          return ''
        }
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          console.log(val, this.id)
          this.fetchData()
        } else {
          this.loading = true
        }
      },
    },
    methods: {
      async fetchData() {
        let { data } = await getDetail({ id: this.id })
        let list = await getList()
        this.form = data
        console.log(this.form)
        if (this.form.driver_id == 0) {
          this.form.driver_id = ''
        } else {
          this.form.driver_id = Number(this.form.driver_id)
        }
        this.driver_list = list.data

        console.log(list)

        this.loading = false
      },
      close() {
        this.showDialog = false
      },
      save() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            console.log('保存')
            console.log(this.form)
            editRow(this.form).then((res) => {
              if (res.code == 200) {
                this.$message.success('修改成功')
                this.showDialog = false
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            console.log('验证失败')
            return false
          }
        })
      },
      // 打开地图
      handlerShowMapDialog() {
        console.log('map')
        this.$refs['baiduMap'].handlerShowMapDialog()
      },
      // // 传入地址
      locationData(val) {
        console.log(val)
        this.form.depot_address = val.address
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-form-item__content {
    width: 80% !important;
  }
</style>
