var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.queryTableForm,
            "label-width": "80px",
            inline: true,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "depot_type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: {
                    filterable: "",
                    "popper-class": "select-idx",
                    placeholder: "全部类型",
                    clearable: "",
                  },
                  on: { change: _vm.hadnleTypeChange },
                  model: {
                    value: _vm.queryTableForm.depot_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryTableForm, "depot_type", $$v)
                    },
                    expression: "queryTableForm.depot_type",
                  },
                },
                _vm._l(_vm.typeList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.value, value: item.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.queryTableForm.depot_type == "2"
            ? _c(
                "el-form-item",
                { attrs: { prop: "depot_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入车辆名称" },
                    model: {
                      value: _vm.queryTableForm.depot_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryTableForm, "depot_name", $$v)
                      },
                      expression: "queryTableForm.depot_name",
                    },
                  }),
                ],
                1
              )
            : _vm.queryTableForm.depot_type == "1"
            ? _c(
                "el-form-item",
                { attrs: { prop: "depot_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入仓库名称" },
                    model: {
                      value: _vm.queryTableForm.depot_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryTableForm, "depot_name", $$v)
                      },
                      expression: "queryTableForm.depot_name",
                    },
                  }),
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { prop: "depot_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.queryTableForm.depot_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryTableForm, "depot_name", $$v)
                      },
                      expression: "queryTableForm.depot_name",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-form-item",
            [_c("el-button", { on: { click: _vm.query } }, [_vm._v("查询")])],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addDepot } },
                [_vm._v("添加仓库")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "tableRef",
              attrs: { stripe: "", data: _vm.tableData },
            },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", type: "index", width: "50" } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "50" },
              }),
              _vm._l(_vm.columnsData, function (colItem, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    align: "center",
                    label: colItem.label,
                    prop: colItem.prop,
                    width: colItem.width,
                  },
                  scopedSlots: _vm._u(
                    [
                      colItem.label === "排序"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.blur_(row, row.sort_order)
                                    },
                                  },
                                  model: {
                                    value: row.sort_order,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        row,
                                        "sort_order",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "row.sort_order",
                                  },
                                }),
                              ]
                            },
                          }
                        : colItem.prop == "depot_type"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.depot_type == 1 ? "仓库" : "车辆"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          }
                        : colItem.prop == "user_count"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var $index = ref.$index
                              var row = ref.row
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "text-decoration": "underline",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlPermissions($index, row)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(row.user_count) + " ")]
                                ),
                              ]
                            },
                          }
                        : colItem.prop == "is_minus"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content:
                                        row.is_minus === false
                                          ? "点击开启"
                                          : "点击关闭",
                                      enterable: false,
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeFKC(row.id)
                                        },
                                      },
                                      model: {
                                        value: row.is_minus,
                                        callback: function ($$v) {
                                          _vm.$set(row, "is_minus", $$v)
                                        },
                                        expression: "row.is_minus",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          }
                        : colItem.prop == "is_close"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.is_close == 0 ? "正常" : "停用"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "160",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit($index, row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        row.is_close != 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryTableForm.pageNo,
              layout: _vm.layout,
              "page-size": _vm.queryTableForm.pageSize,
              total: _vm.queryTableForm.total,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c("edit", { ref: "edit", on: { refresh: _vm.fetchData } }),
      _c("create", { ref: "create", on: { refresh: _vm.fetchData } }),
      _c("permissions", { ref: "permissions", on: { refresh: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }