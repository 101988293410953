var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        title: "权限设置",
        width: "1100px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
            _vm._v(" 仓库名称: "),
            _c("span", [_vm._v(_vm._s(_vm.depot_name))]),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              attrs: { stripe: "", data: _vm.list },
              on: { "select-all": _vm.selectAll, select: _vm.selectcRow },
            },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", type: "index", width: "50" } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "50" },
              }),
              _vm._l(_vm.finallyColumns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.prop,
                    width: "",
                    label: item.label,
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            item.label == "姓名"
                              ? _c("div", [_vm._v(_vm._s(row.user_name))])
                              : item.label == "角色"
                              ? _c("div", [_vm._v(_vm._s(row.role))])
                              : item.label == "电话"
                              ? _c("div", [_vm._v(_vm._s(row.mobile))])
                              : _c(
                                  "div",
                                  [
                                    _c("el-checkbox", {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.selectItem(row, $event)
                                        },
                                      },
                                      model: {
                                        value: row[item.prop],
                                        callback: function ($$v) {
                                          _vm.$set(row, item.prop, $$v)
                                        },
                                        expression: "row[item.prop]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "5px", "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }