<template>
  <!-- NAME[epic=基础] 仓库管理 -->
  <div class="orderTest-container">
    <el-form :model="queryTableForm" label-width="80px" :inline="true">
      <el-form-item prop="depot_type">
        <el-select
          v-model="queryTableForm.depot_type"
          filterable
          :popper-class="'select-idx'"
          placeholder="全部类型"
          style="width: 130px"
          clearable
          @change="hadnleTypeChange"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.value"
            :value="item.label"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="queryTableForm.depot_type == '2'" prop="depot_name">
        <el-input
          v-model="queryTableForm.depot_name"
          placeholder="请输入车辆名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-else-if="queryTableForm.depot_type == '1'"
        prop="depot_name"
      >
        <el-input
          v-model="queryTableForm.depot_name"
          placeholder="请输入仓库名称"
        ></el-input>
        <!-- <el-autocomplete
          v-model="form.depotName"
          :popper-class="'select-idx selectDC'"
          placeholder="输入仓库名称"
          style="width: 180px"
          @select="tableSearch"
        >
          <i
            slot="suffix"
            class="el-icon-search el-input__icon"
            @click="searchIconClick()"
          ></i>
          <template slot-scope="{ item }">
            <div class="wrap" style="display: flex">
              <div class="name" style="margin-right: 10px">
                id:{{ item.id }}
              </div>
              <span class="addr">仓库名称:{{ item.value }}</span>
            </div>
          </template>
        </el-autocomplete> -->
      </el-form-item>
      <el-form-item v-else prop="depot_name">
        <el-input
          v-model="queryTableForm.depot_name"
          placeholder="请输入名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="query">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="addDepot">添加仓库</el-button>
      </el-form-item>
    </el-form>
    <div class="table">
      <el-table
        ref="tableRef"
        v-loading="tableLoading"
        stripe
        :data="tableData"
      >
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="50"
        ></el-table-column>

        <el-table-column
          v-for="(colItem, index) in columnsData"
          :key="index"
          align="center"
          :label="colItem.label"
          :prop="colItem.prop"
          :width="colItem.width"
        >
          <!-- 排序 -->
          <template v-if="colItem.label === '排序'" #default="{ row }">
            <el-input
              v-model.trim="row.sort_order"
              @change="blur_(row, row.sort_order)"
            ></el-input>
          </template>
          <template v-else-if="colItem.prop == 'depot_type'" #default="{ row }">
            {{ row.depot_type == 1 ? '仓库' : '车辆' }}
          </template>
          <template
            v-else-if="colItem.prop == 'user_count'"
            #default="{ $index, row }"
          >
            <span
              style="text-decoration: underline; cursor: pointer"
              @click="handlPermissions($index, row)"
            >
              {{ row.user_count }}
            </span>
          </template>

          <template v-else-if="colItem.prop == 'is_minus'" #default="{ row }">
            <el-tooltip
              :content="row.is_minus === false ? '点击开启' : '点击关闭'"
              :enterable="false"
              placement="top"
            >
              <el-switch
                v-model="row.is_minus"
                @change="changeFKC(row.id)"
              ></el-switch>
            </el-tooltip>
          </template>
          <template v-else-if="colItem.prop == 'is_close'" #default="{ row }">
            {{ row.is_close == 0 ? '正常' : '停用' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160" fixed="right" align="center">
          <template #default="{ $index, row }">
            <el-button type="text" @click="handleEdit($index, row)">
              编辑
            </el-button>
            <el-button
              v-if="row.is_close != 0"
              type="text"
              @click="handleDelete($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryTableForm.pageNo"
        :layout="layout"
        :page-size="queryTableForm.pageSize"
        :total="queryTableForm.total"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
    <edit ref="edit" @refresh="fetchData"></edit>
    <create ref="create" @refresh="fetchData"></create>
    <permissions ref="permissions" @refresh="fetchData"></permissions>
  </div>
</template>
<script>
  import _ from 'lodash'
  import {
    getTableList,
    deleteRow,
    sort,
    toggleFKC,
  } from '@/api/depotManagement'

  import Edit from './components/edit'
  import Create from './components/creat'
  import Permissions from './components/permissions.vue'
  export default {
    name: 'DepotManagement',
    components: {
      Edit,
      Create,
      Permissions,
    },
    data() {
      return {
        layout: 'total, sizes, prev, pager, next, jumper',
        queryTableForm: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
          depot_type: '',
          depot_name: '',
        },
        tableLoading: true,
        typeList: [
          {
            value: '仓库',
            label: '1',
          },
          {
            value: '车辆',
            label: '2',
          },
        ],
        tableData: [],
        checkList: [
          '排序',
          '名称',
          '类型',
          '使用人',
          '人员权限',
          '负库存',
          '状态',
        ],
        columns: [
          {
            order: 0,
            prop: 'sort_order',
            label: '排序',
            width: '90',
          },
          {
            order: 1,
            prop: 'depot_name',
            label: '名称',
            width: 'auto',
          },
          {
            order: 1,
            prop: 'depot_type',
            label: '类型',
            width: '110',
          },
          {
            order: 1,
            prop: 'driver_name',
            label: '使用人',
            width: '110',
          },
          {
            order: 1,
            prop: 'user_count',
            label: '人员权限',
            width: '110',
          },
          {
            order: 1,
            prop: 'is_minus',
            label: '负库存',
            width: '110',
          },
          {
            order: 1,
            prop: 'is_close',
            label: '状态',
            width: '110',
          },
        ],
      }
    },
    computed: {
      columnsData() {
        let endCol = []
        this.checkList.forEach((item) => {
          endCol.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        console.log(_.sortBy(endCol, (item) => item.order))
        return _.sortBy(endCol, (item) => item.order)
      },
    },
    watch: {},
    created() {
      this.fetchData(this.queryTableForm)
    },
    methods: {
      async fetchData() {
        this.tableLoading = true
        let { code, data, msg, totalCount } = await getTableList(
          this.queryTableForm
        )
        this.queryTableForm.total = totalCount
        this.tableData = data
        // 处理开关
        this.tableData.forEach((item) => {
          item.is_minus = item.is_minus == 1 ? true : false
          item.is_close = item.is_close == 1 ? true : false
        })
        console.log(this.tableData)
        this.tableLoading = false
      },
      searchIconClick() {
        console.log('searchIconClick')
      },
      tableSearch() {
        console.log('tableSearch')
      },
      // 表格内商品名称输入框搜索
      createStateFilter(queryString) {
        return (state) => {
          return (
            state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          )
        }
      },
      async querySearch(val, cb) {
        let results = [{}]
      },
      query() {
        console.log('查询')
        this.queryTableForm.pageSize = 10
        this.queryTableForm.pageNo = 1
        this.fetchData()
      },
      addDepot() {
        console.log('添加仓库')
        this.$refs['create'].showDialog = true
      },
      // 表格分页
      handleCurrentChange(val) {
        this.tableLoading = true
        console.log(val)
        this.queryTableForm.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.tableLoading = true
        console.log(val)
        this.queryTableForm.pageSize = val
        this.fetchData()
      },
      // 排序输入框失去焦点 排序
      blur_(row, val) {
        console.log(row.id, val)
        sort({ id: row.id, sort_order: val }).then((res) => {
          if (res.code == 200) {
            this.$message.success('排序成功')
            this.fetchData()
          } else {
            this.$message.error('排序失败')
          }
        })
      },
      changeFKC(id) {
        toggleFKC({ id: id }).then((res) => {
          if (res.code == 200) {
            this.fetchData()
            this.$message.success(res.msg)
          }
        })
      },
      hadnleTypeChange() {
        this.queryTableForm.depot_name = ''
      },
      // 表格权限点击事件
      handlPermissions(index, row) {
        console.log(index, row)
        this.$refs.permissions.showDialog = true
        this.$refs.permissions.id = row.id
      },
      handleEdit(index, row) {
        this.$refs['edit'].showDialog = true
        this.$refs['edit'].id = row.id
      },
      handleDelete(index, row) {
        this.$confirm('确定要删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            console.log(1)
            deleteRow({ id: row.id }).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success(res.msg)
                this.fetchData()
              } else {
                this.$message.error(res.msg)
                this.fetchData()
              }
            })
          })
          .catch(() => {})
      },
    },
  }
</script>
<style lang="scss" scoped></style>
