<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    title="权限设置"
    width="1100px"
  >
    <div class="wrapper">
      <div style="margin-bottom: 10px">
        仓库名称:
        <span>{{ depot_name }}</span>
      </div>
      <!-- 表格 -->
      <el-table
        ref="table"
        v-loading="loading"
        stripe
        :data="list"
        @select-all="selectAll"
        @select="selectcRow"
      >
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="50"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          :prop="item.prop"
          width=""
          :label="item.label"
          align="center"
        >
          <template #default="{ row }">
            <div v-if="item.label == '姓名'">{{ row.user_name }}</div>
            <div v-else-if="item.label == '角色'">{{ row.role }}</div>
            <div v-else-if="item.label == '电话'">{{ row.mobile }}</div>
            <div v-else>
              <el-checkbox
                v-model="row[item.prop]"
                :true-label="1"
                :false-label="0"
                @change="selectItem(row, $event)"
              ></el-checkbox>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 底部按钮 -->
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 5px; text-align: right"
      >
        <el-button type="primary" @click="handleSave">保存</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import { permissionsList, subPermissions } from '@/api/depotManagement'
  export default {
    name: 'Permissions',
    components: {},
    data() {
      return {
        id: 0,
        totalCount: 0,
        loading: true,
        depot_name: '',
        showDialog: false,
        list: [],
        checkList: [
          '姓名',
          '角色',
          '电话',
          // '入库单',
          // '出库单',
          '调拨',
          '盘点',
          '查询',
          '审核',
          '作废',
          // '冲改',
        ],
        columns: [
          {
            order: 1,
            width: '100',
            prop: 'user_name',
            label: '姓名',
          },
          {
            order: 2,
            width: '110',
            prop: 'role',
            label: '角色',
          },
          {
            order: 3,
            width: '150',
            prop: 'mobile',
            label: '电话',
          },
          // {
          //   order: 4,
          //   width: '75',
          //   prop: 'auth_1',
          //   label: '入库单',
          // },
          // {
          //   order: 5,
          //   width: '75',
          //   prop: 'auth_2',
          //   label: '出库单',
          // },
          {
            order: 6,
            width: '75',
            prop: 'auth_3',
            label: '调拨',
          },
          {
            order: 7,
            width: '75',
            prop: 'auth_4',
            label: '盘点',
          },
          {
            order: 8,
            width: '75',
            prop: 'auth_5',
            label: '查询',
          },
          {
            order: 9,
            width: '75',
            prop: 'auth_6',
            label: '审核',
          },
          {
            order: 10,
            width: '75',
            prop: 'auth_7',
            label: '作废',
          },
          // {
          //   order: 11,
          //   width: '75',
          //   prop: 'auth_8',
          //   label: '冲改',
          // },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let arr = []
        this.checkList.forEach((item) => {
          arr.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        return _.sortBy(arr, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.id = 0
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      close() {
        this.showDialog = false
      },
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await permissionsList({
          depot_id: this.id,
        })
        if (code == 200) {
          console.log(data)
          this.depot_name = data.depot_name
          this.list = data.list
          this.$nextTick(() => {
            this.list.forEach((item, index) => {
              if (item.auth_all == 1) {
                this.$refs.table.toggleRowSelection(this.list[index], true)
              }
            })
          })
        }
        this.loading = false
      },
      handleSave() {
        let depotData = []
        this.list.forEach((item) => {
          // item.depot_id = item.id
          depotData.push({
            user_id: item.id,
            auth_value: item,
          })
        })
        console.log(depotData)
        let data = {
          depot_id: this.id,
          depot_data: JSON.stringify(depotData),
          // depot_data: depotData,
        }
        console.log(data)
        subPermissions(data).then((res) => {
          if (res.code == 200) {
            this.$emit('refresh')
            this.$message.success('保存成功')
            this.showDialog = false
          }
        })
      },
      selectAll(val) {
        console.log(val)
        if (val.length == 0) {
          this.list.forEach((item) => {
            Object.keys(item).forEach((k) => {
              if (k.indexOf('auth') == 0) {
                item[k] = 0
              }
            })
          })
        }
        val.forEach((item) => {
          Object.keys(item).forEach((k) => {
            if (k.indexOf('auth') == 0) {
              item[k] = 1
            }
          })
        })
      },
      selectcRow(select, row) {
        console.log(select, row)
        Object.keys(row).forEach((k) => {
          if (k.indexOf('auth') == 0) {
            row[k] = 0
          }
        })
        select.forEach((item) => {
          Object.keys(item).forEach((k) => {
            if (k.indexOf('auth') == 0) {
              item[k] = 1
            }
          })
        })
      },
      selectItem(row, val) {
        console.log(row, val)
        if (val == 0) {
          this.$refs.table.toggleRowSelection(row, false)
        } else if (val == 1) {
          let i = 0
          Object.keys(row).forEach((k) => {
            if (
              k.indexOf('auth_all') != 0 &&
              k.indexOf('auth') == 0 &&
              row[k] == 1
            ) {
              console.log(k)
              i += 1
            }
          })
          console.log(i)
          if (i == 8) {
            this.$refs.table.toggleRowSelection(row, true)
          }
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
