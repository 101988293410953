var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: _vm.title,
            top: "15vh",
            visible: _vm.showDialog,
            width: "780px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "content",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticStyle: { "margin-left": "140px" },
                  attrs: {
                    model: _vm.form,
                    inline: "",
                    rules: _vm.rules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "440px" },
                      attrs: { prop: "depot_name", label: "名称:" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "240px" },
                        model: {
                          value: _vm.form.depot_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "depot_name", $$v)
                          },
                          expression: "form.depot_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "440px" },
                      attrs: { prop: "depot_type", label: "类型:" },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1, disabled: "" },
                          model: {
                            value: _vm.form.depot_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "depot_type", $$v)
                            },
                            expression: "form.depot_type",
                          },
                        },
                        [_vm._v(" 仓库 ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2, disabled: "" },
                          model: {
                            value: _vm.form.depot_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "depot_type", $$v)
                            },
                            expression: "form.depot_type",
                          },
                        },
                        [_vm._v(" 车辆 ")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.depot_type == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "depot_address", label: "地址:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "240px" },
                            model: {
                              value: _vm.form.depot_address,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "depot_address", $$v)
                              },
                              expression: "form.depot_address",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "10px",
                                cursor: "pointer",
                              },
                              on: { click: _vm.handlerShowMapDialog },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-location",
                                staticStyle: { color: "#409eff" },
                              }),
                              _vm._v(" 地图定位 "),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.depot_type == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "depot_carno", label: "车牌号:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "240px" },
                            model: {
                              value: _vm.form.depot_carno,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "depot_carno", $$v)
                              },
                              expression: "form.depot_carno",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.depot_type == 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "driver_id",
                            required: "",
                            label: "使用人:",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "240px" },
                              attrs: {
                                filterable: "",
                                placeholder: "选择销售或送货人",
                              },
                              model: {
                                value: _vm.form.driver_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "driver_id", $$v)
                                },
                                expression: "form.driver_id",
                              },
                            },
                            _vm._l(_vm.driver_list, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label: item.user_name,
                                    value: item.id,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.user_name))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        "font-size": "13px",
                                        color: "#8492a6",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.mobile) + " ")]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.label, prop: "" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.form.is_minus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "is_minus", $$v)
                            },
                            expression: "form.is_minus",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.tipa) + " ")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.depot_type == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "下单限制", prop: "" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { "true-label": 1, "false-label": 0 },
                              model: {
                                value: _vm.form.is_limit_order,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "is_limit_order", $$v)
                                },
                                expression: "form.is_limit_order",
                              },
                            },
                            [_vm._v(" 按可用库存数量限制访销、网销下单数量 ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "调拨设置:", prop: "" } },
                    [
                      _vm.form.depot_type == 1
                        ? _c(
                            "el-checkbox",
                            {
                              attrs: { "true-label": 1, "false-label": 0 },
                              model: {
                                value: _vm.form.is_limit_allot,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "is_limit_allot", $$v)
                                },
                                expression: "form.is_limit_allot",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.tipb) + " ")]
                          )
                        : _vm._e(),
                      _vm.form.depot_type == 2
                        ? _c(
                            "el-checkbox",
                            {
                              attrs: { "true-label": 1, "false-label": 0 },
                              model: {
                                value: _vm.form.is_allot,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "is_allot", $$v)
                                },
                                expression: "form.is_allot",
                              },
                            },
                            [
                              _vm._v(" 车存货值大于 "),
                              _c("el-input", {
                                staticStyle: { width: "70px" },
                                model: {
                                  value: _vm.form.allot_money,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "allot_money", $$v)
                                  },
                                  expression: "form.allot_money",
                                },
                              }),
                              _vm._v(" 元，不允许调拨 "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "440px" },
                      attrs: { prop: "is_close", label: "状态:" },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.form.is_close,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "is_close", $$v)
                            },
                            expression: "form.is_close",
                          },
                        },
                        [_vm._v("正常")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.form.is_close,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "is_close", $$v)
                            },
                            expression: "form.is_close",
                          },
                        },
                        [_vm._v("停用")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v("保存")]
                  ),
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("关闭"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("mine-map", {
            ref: "baiduMap",
            on: { "location-data": _vm.locationData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }